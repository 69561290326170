import  "../scss/main.scss";  // Import main styles

// Auto-update copyright-text
let cYear = new Date().getFullYear();
document.getElementById("copyright-year").innerHTML = (cYear != "2019") ? ("2019-" + cYear) : "2019";


function clearFormWarnings() {
    document.getElementById("name").className = "";
    document.getElementById("tlf").className = ""
    document.getElementById("email").className = ""
    document.getElementById("msg").className = ""
}

function clearFormData() {
    document.getElementById("name").value = "";
    document.getElementById("tlf").value = "";
    document.getElementById("email").value = "";
    document.getElementById("msg").value = "";
    clearFormWarnings();
}

function submitContactForm() {
    clearFormWarnings();

    var inputValid = true;

    // Check that name is not empty
    var uName  = document.getElementById("name").value;
    if (uName == "") {
        document.getElementById("name").className = "validation-failed";
        inputValid = false;
    }


    var uPhone = document.getElementById("tlf").value;

   // Check that email is valid
    var uEmail = document.getElementById("email").value;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(uEmail)) {
        document.getElementById("email").className = "validation-failed";
        inputValid = false;
    }

    // Check that message is not empty
    var uMsg   = document.getElementById("msg").value;
    if (uMsg == "") {
        document.getElementById("msg").className = "validation-failed";
        inputValid = false;
    }

    // If any of the validations failed, dont bother to run the request
    if (!inputValid) { return; }
    console.log(window.btoa(encodeURIComponent(uMsg)));
    // Assemble the request url, ude encodeURIComponents to avoid trouble with spaces and special chars
    var requestURI = "./contact-api.php?" +
        "name=" + window.btoa(encodeURIComponent(uName)) +
        "&phone=" + window.btoa(encodeURIComponent(uPhone)) +
        "&email=" + window.btoa(encodeURIComponent(uEmail)) +
        "&message=" + window.btoa(encodeURIComponent(uMsg));

    var submitButton = document.getElementById("submitButton");
    // Start button waiting animation
    submitButton.className = "onclic";

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
        if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
            if (xmlhttp.status == 200) {
                
                try {
                    let response = JSON.parse(xmlhttp.responseText);

                    if (response.success) {
                        console.log("Message sent.");
                    } else {
                        throw {
                            name:"api-not-happy",
                            "message": (
                                "[" + response.code + "]: " + response.msg
                            ),
                            "responsemsg": response.msg
                        };
                    }

                    // Show the checkmark button symbol for a second and a half
                    submitButton.className = "validate";
                    setTimeout(function() {
                        submitButton.className = "";
                    }, 1500);

                    clearFormData();

                } catch (e) {
                    // Show button error indication for 1,5s
                    submitButton.className = "error";
                    setTimeout(function() {
                        submitButton.className = "";
                    }, 1500);

                    if (e.name == "api-not-happy") {
                        alert("Feil: \n" + e.responsemsg);
                        throw new Error(e.message);
                    } else {
                        throw new Error("ERR [invalidResponse]: Raw dump:\n" + xmlhttp.responseText);
                    }
                }

            } else if (xmlhttp.status == 400) {
                alert('There was an error 400');

                submitButton.className = "error";
                setTimeout(function() {
                    submitButton.className = "";
                }, 1500);

            } else {
                alert('something else other than 200 was returned');

                submitButton.className = "error";
                setTimeout(function() {
                    submitButton.className = "";
                }, 1500);
            }
        }
    }

    // Just to make sure our button animation works, we add a delay here
    setTimeout(() => {
        xmlhttp.open("GET", requestURI, true);
        xmlhttp.setRequestHeader("Content-type", "application/x-qqq-form-urlencoded");
        xmlhttp.send();
    }, 400);
    
}

// Submit-button should do something
document.getElementById("submitButton").addEventListener("click", submitContactForm);

// Remove all nojs-warnings
Array.from(document.getElementsByClassName("nojs")).forEach((el) => {
    el.style["display"] = "none";
});
// Show the components that require javascript to work
document.getElementById("contactform-form").className = "js-enabled";